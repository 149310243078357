<template>
  <v-navigation-drawer
    :value="isAddNewChefSidebarActive"
    temporary
    :permanent="isAddNewChefSidebarActive"
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-new-chef-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Chef</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-chef-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="chefData.name"
            outlined
            dense
            :rules="[validators.required]"
            label="Chef Name"
            placeholder="John Doe"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-select
            v-model="chefData.kitchen_id"
            placeholder="kitchen Name"
            label="kitchen Name"
            :items="kitchenList"
            :rules="[validators.required]"
            item-text="name"
            item-value="id"
            outlined
            class="mb-6"
            dense
            hide-details
          ></v-select>

          <vue-tel-input-vuetify
            @onInput="onInput_contact_number"
            v-model="chefData.mobile_number"
            defaultCountry="JO"
            :onlyCountries="['JO', 'SA']"
            @keypress="isNumber($event)"
            @paste.prevent
            :rules="[validators.validtelfun(chefData.mobile_number, validtel)]"
            :maxLen="18"
            label="Mobile Number"
            placeholder="+96279854****"
            class="uppercase"
          ></vue-tel-input-vuetify>

          <v-card-text v-if="message.mobile_number" style="color: #ff3342">
            {{ message.mobile_number[0] }}
          </v-card-text>

          <vue-tel-input-vuetify
            @onInput="onInput_emergency_number"
            v-model="chefData.emergency_number"
            defaultCountry="JO"
            :onlyCountries="['JO', 'SA']"
            @keypress="isNumber($event)"
            @paste.prevent
            :rules="[validators.validtelfun(chefData.emergency_number, validtel2)]"
            :maxLen="18"
            label="Emergency Number"
            placeholder="+96279854****"
            class="uppercase"
          ></vue-tel-input-vuetify>

          <v-card-text v-if="message.emergency_number" style="color: #ff3342">
            {{ message.emergency_number[0] }}
          </v-card-text>
          
          <v-file-input
            v-model="chefData.image"
            accept=".jpg, .jpeg, .png"
            placeholder="Choose an image"
            outlined
            type="file"
            dense
            label="Upload Image"
            class="mb-1"
            @change="message = ''"
            :rules="
              message.image
                ? [message.image[0]]
                : [validators.required, fileAccept(['jpg', 'jpeg', 'png'], chefData.image)]
            "
          >
          </v-file-input>
          <span
            v-if="showHint"
            class="mb-6"
            :style="message.image && 'color:red'"
            style="display: block; font-size: 11px"
            >Image dimensions: min-width: 126px, max-width: 252px, min-height: 95px, max-height: 189px, max-file-size:
            500kb</span
          >

          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetchefData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-navigation-drawer>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { imageValidator, integerValidator, required,validtelfun } from '@core/utils/validation'
import { mdiClipboardFileOutline, mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import { isString } from '@vueuse/shared'

export default {
  data() {
    return {
      showHint: true,
    }
  },
  methods: {
    hideHint() {
      this.showHint = false
    },
  },
  model: {
    prop: 'isAddNewChefSidebarActive',
    event: 'update:is-add-new-chef-sidebar-active',
  },
  props: {
    isAddNewChefSidebarActive: {
      type: Boolean,
      required: true,
    },
    kitchenList: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankChefData = {
      name: '',
      mobile_number: '',
      emergency_number: '',
      image: null,
    }
    const isLoading = ref(false)

    const valid = ref(false)
    const form = ref(null)
    const message = ref('')
    const validtel = ref(false)
    const validtel2 = ref(false)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const chefData = ref(JSON.parse(JSON.stringify(blankChefData)))
    const resetchefData = () => {
      chefData.value = JSON.parse(JSON.stringify(blankChefData))
      resetForm()
      emit('update:is-add-new-chef-sidebar-active', false)
    }

    const fileAccept = (accept, data) => {
      if (data && !isString(data)) {
        if (data.size > 500000) {
          return `MAX SIZE 500KB`
        }
        if (accept.includes(data.name.split('.')[1])) {
          return true
        } else {
          return `Please choose file path (${accept})`
        }
      }
      return true
    }
    const onInput_contact_number = ({ number, isValid }) => {
      if (number?.e164) {
        chefData.value.mobile_number = number.e164
        validtel.value = isValid
      } else {
        validtel.value = false
      }
      let tester = /^(\+\d{0,}\s?\d{0,}\s?\d{0,}\s?\d{0,})?$/.test(number.input)
      let tester2 = /^(\+\d{0,}\s?\d{0,}[-]?\d{0,}[-]?\d{0,})?$/.test(number.input)
      if (!tester && !tester2) {
        validtel.value = false
      }
    }

    const onInput_emergency_number = ({ number, isValid }) => {
      if (number?.e164) {
        chefData.value.emergency_number = number.e164
        validtel2.value = isValid
      } else {
        validtel2.value = false
      }
      let tester = /^(\+\d{0,}\s?\d{0,}\s?\d{0,}\s?\d{0,})?$/.test(number.input)
      let tester2 = /^(\+\d{0,}\s?\d{0,}[-]?\d{0,}[-]?\d{0,})?$/.test(number.input)
      if (!tester && !tester2) {
        validtel2.value = false
      }
    }
    const onSubmit = () => {
      if (valid.value) {
        isLoading.value = true
        store
          .dispatch('app-chef/addChef', chefData.value)
          .then(response => {
            message.value = ''
            isLoading.value = false
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: response.data.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
            emit('refetch-data')
            resetchefData()
          })
          .catch(error => {
            isLoading.value = false
            console.log('error', error)
            if (error?.data?.message) {
              message.value = error?.data?.message
              console.log('message', message.value)
            }
          })
      } else {
        validate()
      }
    }

    return {
      isLoading,
      validtel2,
      validtel,
      onInput_contact_number,
      onInput_emergency_number,
      message,
      fileAccept,
      form,
      countries,
      chefData,
      valid,
      resetchefData,
      onSubmit,
      validate,

      // validation
      validators: {
        integerValidator,
        required,
        validtelfun,
        imageValidator,
      },
      icons: {
        mdiClose,
        mdiClipboardFileOutline,
      },
    }
  },
}
</script>
